import { jsx as _jsx } from "react/jsx-runtime";
import { Suspense, lazy } from 'react';
// components
import LoadingScreen from '../components/loading-screen';
// ----------------------------------------------------------------------
const Loadable = (Component) => (props) => (_jsx(Suspense, { fallback: _jsx(LoadingScreen, {}), children: _jsx(Component, { ...props }) }));
// ----------------------------------------------------------------------
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')));
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')));
export const UpdatePassword = Loadable(lazy(() => import('../pages/auth/UpdatePassword')));
export const LabPage = Loadable(lazy(() => import('../pages/Lab')));
export const Dashboard = Loadable(lazy(() => import('../pages/dashboard/index')));
export const ProfileLabo = Loadable(lazy(() => import('../pages/ProfileLabo')));
export const ProfileCabinet = Loadable(lazy(() => import('../pages/ProfileCabinet')));
export const Categories = Loadable(lazy(() => import('../pages/Categories')));
export const Patient = Loadable(lazy(() => import('../pages/Patient')));
export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const PatientForm = Loadable(lazy(() => import('../pages/PatientForm')));
export const CabinetPage = Loadable(lazy(() => import('../pages/Cabinet')));
export const PermissionPage = Loadable(lazy(() => import('../pages/Permission')));
export const ArchivedPatientPage = Loadable(lazy(() => import('../pages/PatientArchive')));
export const ArchivedCabinetPage = Loadable(lazy(() => import('../pages/CabinetArchive')));
export const OptionsPage = Loadable(lazy(() => import('../pages/config/Options')));
export const Notifications = Loadable(lazy(() => import('../pages/Notifications')));
export const DraftCommandPage = Loadable(lazy(() => import('../pages/DraftCommand')));
export const TypesPage = Loadable(lazy(() => import('../pages/Types')));
export const ProsthetistPage = Loadable(lazy(() => import('../pages/Prosthetist')));
export const OrdersPage = Loadable(lazy(() => import('../pages/Orders')));
export const ArchivedOrdersPage = Loadable(lazy(() => import('../pages/OrdersArchived')));
export const OrderViewOne = Loadable(lazy(() => import('../pages/OrderViewOne')));
export const DentistPage = Loadable(lazy(() => import('../pages/Dentist')));
export const ArticlePage = Loadable(lazy(() => import('../pages/config/Article')));
export const TreatmentViewPage = Loadable(lazy(() => import('../pages/TreatmentView')));
export const ConsultCommand = Loadable(lazy(() => import('../pages/ConsultCommand')));
export const Calendar = Loadable(lazy(() => import('../pages/CalendarPage')));
